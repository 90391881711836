import { Gender, PhoneNumber } from "./users";
import axios from "axios";

export enum Permission {
    CT_ASSESS_CDRS = "CT_ASSESS_CDRS",
    CT_CREATE_CITNOW_CONVERSATIONS_TWILIO_SUB_ACCOUNT = "CT_CREATE_CITNOW_CONVERSATIONS_TWILIO_SUB_ACCOUNT",
    CT_CREATE_PARTITIONS = "CT_CREATE_PARTITIONS",
    CT_DEACTIVATE_PARTITIONS = "CT_DEACTIVATE_PARTITIONS",
    CT_DEPLOY_CUSTOM_IMAGE = "CT_DEPLOY_CUSTOM_IMAGE",
    CT_EXECUTE_PARTITION_SELECT_QUERY = "CT_EXECUTE_PARTITION_SELECT_QUERY",
    CT_HOUSEKEEP_INVENTORY = "CT_HOUSEKEEP_INVENTORY",
    CT_HOUSEKEEP_STORAGE = "CT_HOUSEKEEP_STORAGE",
    CT_IS_AGENT = "CT_IS_AGENT",
    CT_IS_USER = "CT_IS_USER",
    CT_MANAGE_BDC_TEAMS = "CT_MANAGE_BDC_TEAMS",
    CT_MANAGE_BILLING_ACCOUNTS = "CT_MANAGE_BILLING_ACCOUNTS",
    CT_MANAGE_CITNOW_DATA_EXCHANGE = "CT_MANAGE_CITNOW_DATA_EXCHANGE",
    CT_MANAGE_DATABASES = "CT_MANAGE_DATABASES",
    CT_MANAGE_CONTRACTS = "CT_MANAGE_CONTRACTS",
    CT_MANAGE_DEALERS = "CT_MANAGE_DEALERS",
    CT_MANAGE_DUTY_ROSTER = "CT_MANAGE_DUTY_ROSTER",
    CT_MANAGE_ELASTIC_SEARCH = "CT_MANAGE_ELASTIC_SEARCH",
    CT_MANAGE_LOAD_BALANCERS = "CT_MANAGE_LOAD_BALANCERS",
    CT_MANAGE_INCOMING_EMAILS = "CT_MANAGE_INCOMING_EMAILS",
    CT_MANAGE_INCOMING_EMAIL_BLACKLIST = "CT_MANAGE_INCOMING_EMAIL_BLACKLIST",
    CT_MANAGE_INVOICES = "CT_MANAGE_INVOICES",
    CT_MANAGE_FTP_ACCOUNTS = "CT_MANAGE_FTP_ACCOUNTS",
    CT_MANAGE_PARTITIONS = "CT_MANAGE_PARTITIONS",
    CT_MANAGE_USERS = "CT_MANAGE_USERS",
    CT_MARK_INVOICES_AS_PAID = "CT_MARK_INVOICES_AS_PAID",
    CT_REBUILD_INDEXES = "CT_REBUILD_INDEXES",
    CT_SEND_MARKETING_EMAILS = "CT_SEND_MARKETING_EMAILS",
    CT_START_PARTITIONS = "CT_START_PARTITIONS",
    CT_VIEW_ACTIONS = "CT_VIEW_ACTIONS",
    CT_VIEW_AGENT_DAY_OVERVIEW = "CT_VIEW_AGENT_DAY_OVERVIEW",
    CT_VIEW_AGENT_MONTH_OVERVIEW = "CT_VIEW_AGENT_MONTH_OVERVIEW",
    CT_VIEW_AGENT_STATISTICS = "CT_VIEW_AGENT_STATISTICS",
    CT_VIEW_BDC_REPORTS = "CT_VIEW_BDC_REPORTS",
    CT_VIEW_BDC_TEAMS = "CT_VIEW_BDC_TEAMS",
    CT_VIEW_BENEFIT_REVENUES = "CT_VIEW_BENEFIT_REVENUES",
    CT_VIEW_BWA = "CT_VIEW_BWA",
    CT_VIEW_BWA_REVENUES = "CT_VIEW_BWA_REVENUES",
    CT_VIEW_CDRS = "CT_VIEW_CDRS",
    CT_VIEW_CITNOW_CONVERSATIONS_TWILIO_SUB_ACCOUNT_API_KEY = "CT_VIEW_CITNOW_CONVERSATIONS_TWILIO_SUB_ACCOUNT_API_KEY",
    CT_VIEW_CONTRACTS = "CT_VIEW_CONTRACTS",
    CT_VIEW_DEALER_DIRECTORY = "CT_VIEW_DEALER_DIRECTORY",
    CT_VIEW_DEALER_STATISTICS = "CT_VIEW_DEALER_STATISTICS",
    CT_VIEW_EMERGENCY_INCIDENTS = "CT_VIEW_EMERGENCY_INCIDENTS",
    CT_VIEW_INVOICES = "CT_VIEW_INVOICES",
    CT_VIEW_OWN_AGENT_DAY_OVERVIEW = "CT_VIEW_OWN_AGENT_DAY_OVERVIEW",
    CT_VIEW_OWN_AGENT_MONTH_OVERVIEW = "CT_VIEW_OWN_AGENT_MONTH_OVERVIEW",
    CT_VIEW_RECORDINGS = "CT_VIEW_RECORDINGS",
    CT_VIEW_WHATSAPP_ACCOUNTS = "CT_VIEW_WHATSAPP_ACCOUNTS",
    HOUSEKEEP_STORAGE = "HOUSEKEEP_STORAGE",
    MANAGE_EMERGENCY_INCIDENTS = "MANAGE_EMERGENCY_INCIDENTS",
    MANAGE_INCOMING_EMAILS = "MANAGE_INCOMING_EMAILS",
    MANAGE_INVENTORY_IMPORT = "MANAGE_INVENTORY_IMPORT",
    REBUILD_INDEXES = "REBUILD_INDEXES",
    REMOVE_INVENTORY_VEHICLE_DUPLICATES = "REMOVE_INVENTORY_VEHICLE_DUPLICATES",
}

export interface UserProfile {
    readonly id: string;
    readonly username: string;
    readonly permissions: Permission[];
    readonly timeZone: string;
    readonly locale: string;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly birthday: string | null;
    readonly darkmode: boolean;
    readonly profileImageHash: string | null;
    readonly csrfToken: string;
    readonly weeklyWorkingHours: number | null;
    readonly forcePasswordChange: boolean;
    readonly employeeNumber: string | null;
    readonly emailAddress: string | null;
    readonly phoneNumbers: PhoneNumber[];
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
}

export interface LoginForm {
    readonly username: string;
    readonly password: string;
}

export interface UserProfileForm {
    readonly timeZone: string;
    readonly locale: string;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly birthday: string | null;
    readonly darkmode: boolean;
    readonly profileImage: string | null;
    readonly emailAddress: string | null;
    readonly phoneNumbers: PhoneNumber[];
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
}

export interface PasswordForm {
    readonly oldPassword: string;
    readonly newPassword: string;
}

interface UserSessionApi {
    login(loginForm: LoginForm, passwordForm?: PasswordForm): Promise<UserProfile>;
    logout(): Promise<void>;
    currentUser(): Promise<UserProfile>;
    edit(userProfileForm: UserProfileForm): Promise<UserProfile>;
    changePassword(passwordForm: PasswordForm): Promise<UserProfile>;
}

export const userSessionApi: UserSessionApi = {
    async login(loginForm, passwordForm) {
        const formData = new FormData();

        formData.append("loginForm", new Blob([JSON.stringify(loginForm)], { type: "application/json" }));

        if (passwordForm) {
            formData.append("passwordForm", new Blob([JSON.stringify(passwordForm)], { type: "application/json" }));
        }

        return (await axios.post("/api/user/_login", formData)).data;
    },

    async logout() {
        await axios.post("/api/user/_logout");
    },

    async currentUser() {
        return (await axios.get("/api/user")).data;
    },

    async edit(userProfileForm) {
        return (await axios.put(`/api/user`, userProfileForm)).data;
    },

    async changePassword(passwordForm) {
        return (await axios.put(`/api/user/password`, passwordForm)).data;
    },
};
